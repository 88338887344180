import React from "react";
import heroimage from "../Images/Hero.png";
import heroback from "../Images/Hero-back.jpeg";

const HeroSection = () => {
  return (
    <div className="container-sm" style={{ overflowX: "hidden" }}>
      <div className="row mCenterText">
        <div className="col-md-7 mt-5">
          <div className="text-[#909090] text-base font-medium mt-2" data-aos="fade-up" data-aos-delay="50">
            YOUR DIGITAL BUSINESS CARD
          </div>
          <div className="text-6xl leading-none mt-2 elve" data-aos="fade-up" data-aos-delay="400">
            Elevate your <br />
            <span className="text-[#0E77D3] prof">
              professional presence
            </span>
            <br />
            with Modishcard
          </div>
          <div className="text-[#909090] text-base mt-4" data-aos="fade-up" data-aos-delay="600">
            Discover the seamless integration of technology and networking.
            Modishcard brings to you the revolutionary concept of digital business
            cards, enriched with AI, redefining how you connect and expand your
            professional network.
          </div>
          <button className="bg-[#0E77D3] px-[32px] py-[12px] text-[16px] text-[white] rounded-md mt-6 hover:bg-[#00437E]" data-aos="fade-up" data-aos-delay="800">
            <a href="/app/login"> Go To WebApp</a>
          </button>
          <div style={{ height: 1 }} className="mobMg-30"> </div>
        </div>
        <div className="relative mHide col-md-5 w-[40%] pt-[76px] overflow-hidden">
          <img
            src={heroback}
            alt=""
            className="absolute -z-10 top-[10px] w-[100%] right-[7%] rotate"
            animate-spin
          />
          <img src={heroimage} alt="" className="w-[100%] max-w-[530px] h-auto" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
